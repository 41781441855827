import React, { lazy, memo, Suspense } from 'react';
import { AppPlugin, AppRootProps, OrgRole } from '@grafana/data';

import { getFaroConfig } from './faroConfig';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';

// @ts-ignore
import packageJson from '../package.json';
import pluginJson from './plugin.json';
import { getContextUser } from './utils/user';
import { ContextUser } from './types/ContextServType';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { LoadingPlaceholder } from '@grafana/ui';
import { PLUGIN_BASE_URL } from './utils/utils.routing';
import { ServiceDashboardExtensionProps } from 'components/ExposedComponents/ServiceDashboard/types';
import { AppConfigProps } from './components/AppConfig/AppConfig';
const LazyApp = lazy(() => import('./components/App/App'));
const LazyAppConfig = lazy(() => import('./components/AppConfig/AppConfig'));
const LazyServiceDashboardExtension = lazy(
  () => import('./components/ExposedComponents/ServiceDashboard/ServiceDashboardExtension')
);

const App = (props: AppRootProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyApp {...props} />
  </Suspense>
);

const ServiceDashboardExtension = (props: ServiceDashboardExtensionProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyServiceDashboardExtension {...props} />
  </Suspense>
);

const AppConfig = (props: AppConfigProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyAppConfig {...props} />
  </Suspense>
);

const { environment, url, name } = getFaroConfig();
const FARO_GLOBAL_OBJECT_KEY = 'csp-app-faro';

const user: ContextUser = getContextUser() ?? {
  id: -1,
  name: '',
  orgId: -1,
  email: '',
  orgName: '',
  orgRole: OrgRole.Viewer,
};

export const faro = Object.prototype.hasOwnProperty.call(window, FARO_GLOBAL_OBJECT_KEY)
  ? (window as any)[FARO_GLOBAL_OBJECT_KEY]
  : initializeFaro({
      url,
      app: {
        name,
        version: packageJson.version,
        environment,
      },
      isolate: true,
      globalObjectKey: FARO_GLOBAL_OBJECT_KEY,
      beforeSend: (event) => {
        const isOnPluginPage = (event.meta.page?.url ?? '').includes(PLUGIN_BASE_URL);
        if (!isOnPluginPage) {
          return null;
        }

        return event;
      },
      user: {
        id: String(user.id),
        username: user.name,
        attributes: {
          orgId: String(user.orgId),
        },
      },
      instrumentations: [
        // Mandatory, omits default instrumentations otherwise.
        ...getWebInstrumentations(),

        // Tracing package to get end-to-end visibility for HTTP requests.
        new TracingInstrumentation(),
      ],
    });

const SERVICE_DASHBOARD_EXTENSION_V1 = `${pluginJson.id}/service-dashboard-extension/v1`;

const plugin = new AppPlugin<{}>().setRootPage(App).addConfigPage({
  title: 'Configuration',
  icon: 'cog',
  body: AppConfig,
  id: 'configuration',
});

if (plugin.exposeComponent) {
  plugin.exposeComponent({
    id: SERVICE_DASHBOARD_EXTENSION_V1,
    title: 'Service Overview dashboard',
    description: 'Service Overview dashboard extension',
    component: memo(ServiceDashboardExtension),
  });
}

export { plugin };
